// FAQ

.faq {
    .card {
        position      : relative;
        display       : flex;
        flex-direction: column;
        margin-bottom : 40px;
        @include media-breakpoint-down(md) {
            margin-bottom: 32px;
        }
        @include media-breakpoint-down(sm) {
            margin-bottom: 24px;
        }

        .card-header {
            border    : none;
            padding   : 0 24px 16px 0;
            transition: all .3s ease-in-out;
            cursor    : pointer;

            &::before {
                position   : absolute;
                top        : 4px;
                right      : 0;
                content    : '\e802';
                font-family: 'fontello';
                font-size  : 16px;
                @include media-breakpoint-down(sm) {
                    top: 1px;
                }
            }

            &.collapsed {
                color     : $go4h--text;
                background: none;

                &::before {
                    content: '\e804';
                }
            }

            .card-plus {
                display: flex;
            }
        }

        .card-body {
            padding-bottom: 8px;

            &.last {
                padding-bottom: 0;
            }
        }

        hr {
            width : 100%;
            margin: 0;
            color : $gray-20;
        }
    }
}
