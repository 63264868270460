// Log In Page / Forgot Pass - change - request

.pages {
    position       : relative;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    min-height     : calc(100vh - 80px);
    @include media-breakpoint-down(md) {
        min-height    : calc(100vh - 60px);
        padding-bottom: 20px;
    }
    @include media-breakpoint-down(sm) {
        min-height: calc(100vh - 40px);
    }

    .log-in {
        margin: auto 0;
    }

    .info {
        margin-bottom: 32px;
        @include media-breakpoint-down(sm) {
            margin-bottom: 16px;
        }
    }

    .forgot-pass {
        display        : flex;
        justify-content: space-between;

        a {
            text-decoration: none;
            transition     : all .3s ease-in-out;
            @include hover-state() {
                text-decoration: underline;
            }
        }

    }

    .img-box {
        .img-fluid {
            width : 85%;
            height: auto;
            @include media-breakpoint-down(md) {
                width: 60%;
            }
            @include media-breakpoint-down(sm) {
                width: 40%;
            }
        }

        .splash {
            position: absolute;

            &.splash-left {
                top : 0;
                left: 0;
                @include media-breakpoint-down(md) {
                    top: -60px;
                }
                @include media-breakpoint-down(sm) {
                    top: 0;
                }
            }

            &.splash-right {
                bottom: -5px;
                right : -85px;
                @include media-breakpoint-down(md) {
                    right: -226px;
                }
                @include media-breakpoint-down(sm) {
                    right: -350px;
                }
                @include media-breakpoint-down(xm) {}

            }
        }
    }

}
