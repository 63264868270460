.coach {
    background: $go4h--gray__dark;
    position  : relative;
    z-index   : 1;

    .splash {
        position: absolute;

        &.bot-right {
            right  : 40%;
            top    : -24%;
            z-index: -1;
            @include media-breakpoint-down(md) {
                right: 15%;
                top  : -15%;
            }
            @include media-breakpoint-down(xm) {
                right: -10%;
                top  : -13%;
            }

            img {
                @include media-breakpoint-down(md) {
                    width: 80%;
                }
            }
        }
    }

    .container-fluid {
        background: $go4h--gray__dark;
    }

    .content {
        color: $white;

        .columns {
            @include media-breakpoint-down(xm) {
                margin-bottom: 16px;
            }

            ul {
                @include media-breakpoint-down(xm) {
                    margin-bottom: 0;
                }
            }
        }
    }

    .img-wrapper {
        @include aspect-ratio(1,1);

        position: relative;
        @include media-breakpoint-down(md) {
             @include aspect-ratio(4,3);
        }
        @include media-breakpoint-down(xm) {
             @include aspect-ratio(1,1);
        }
    }

    .img-box {
        @include aspect-ratio(1,1);

        overflow : hidden;
        position : absolute;
        display  : flex;
        max-width: 280px;
        @include media-breakpoint-down(lg) {
            max-width: 230px;
        }
        @include media-breakpoint-down(md) {
            @include aspect-ratio(4,3);

            max-width: 320px;
        }
        @include media-breakpoint-down(sm) {
            max-width: 260px;
        }
        @include media-breakpoint-down(xm) {
            @include aspect-ratio(1,1);

            max-width: 230px;
        }
        @include media-breakpoint-down(xs) {
            max-width: 190px;
        }

        &.top {
            top          : 0;
            right        : 0;
            border-radius: 16px;
            @include media-breakpoint-down(lg) {
                top: 0;
            }
            @include media-breakpoint-down(md) {
                border-radius: 12px;
            }
        }

        &.bot {
            bottom       : 0;
            left         : 0;
            background   : $go4h--gray__dark;
            border       : 12px solid $go4h--gray__dark;
            border-radius: 16px;
            @include media-breakpoint-down(xl) {
                border       : 8px solid $go4h--gray__dark;
                border-radius: 12px;
            }
            @include media-breakpoint-down(lg) {
                border: 8px solid $go4h--gray__dark;
                bottom: 0;
            }
            @include media-breakpoint-down(md) {
                border-radius: 12px;
            }
            @include media-breakpoint-down(sm) {
                border: 6px solid $go4h--gray__dark;
                border-radius: 8px;
            }

            img {
                border-radius: 16px;
                @include media-breakpoint-down(xl) {
                    border-radius: 12px;
                }
                @include media-breakpoint-down(sm) {
                    border-radius: 8px;
                }
            }
        }

        img {
            width     : 100%;
            object-fit: cover;

        }
    }
}
