/* Base Elements */

html {
    box-sizing        : border-box;
    -ms-overflow-style: scrollbar;
}

*, *::after, *::before {
    box-sizing: inherit;
}

.table, body, html, label, ol, table, ul {
    margin : 0;
    padding: 0;
}

body {
    overflow      : auto;
    font-family   : $font-main;
    color         : $go4h--text;
    background    : $go4h--gray__light;

    min-height    : 100vh;
    display       : flex;
    flex-direction: column;
}

// Interactions
.menu-open, .no-scroll {
    overflow: hidden;
}

main {

    overflow  : hidden;
    margin-top: 80px;
    @include media-breakpoint-down(xxl) {
        padding-top: 80px;
        // padding-bottom: 80px;
    }
    @include media-breakpoint-down(md) {
        padding-top: 60px;
        // padding-bottom: 60px;
    }
    @include media-breakpoint-down(sm) {
        padding-top: 40px;
        // padding-bottom: 40px;
    }

    &.home {
        background: $go4h-white;
    }

    &.no-padding {
        @include media-breakpoint-down(xxl) {
            padding-top: 0;
        }
        @include media-breakpoint-down(md) {
            padding-top: 0;
        }
        @include media-breakpoint-down(sm) {
            padding-top: 0;
        }
    }
}

figure {
    margin: 0;
}

// Grid system
section {
    &.gap-left {
        padding-left: $grid-gap-xl;
        @include media-breakpoint-down(lg) {
            padding-left: $grid-gap-lg;
        }
        @include media-breakpoint-down(md) {
            padding-left: $grid-gap-md;
        }
        @include media-breakpoint-down(xm) {
            padding-left: $grid-gap-xm;
        }
        @include media-breakpoint-down(xs) {
            padding-left: $grid-gap-xs;
        }
    }

    &.gap-right {
        padding-right: $grid-gap-xl;
        @include media-breakpoint-down(lg) {
            padding-right: $grid-gap-lg;
        }
        @include media-breakpoint-down(md) {
            padding-right: $grid-gap-md;
        }
        @include media-breakpoint-down(xm) {
            padding-right: $grid-gap-xm;
        }
        @include media-breakpoint-down(xs) {
            padding-right: $grid-gap-xs;
        }
    }

    &.gap-both {
        padding-right: $grid-gap-xl;
        padding-left : $grid-gap-xl;
        @include media-breakpoint-down(lg) {
            padding-right: $grid-gap-lg;
            padding-left : $grid-gap-lg;
        }
        @include media-breakpoint-down(md) {
            padding-right: $grid-gap-md;
            padding-left : $grid-gap-md;
        }
        @include media-breakpoint-down(xm) {
            padding-right: $grid-gap-xm;
            padding-left : $grid-gap-xm;
        }
        @include media-breakpoint-down(xs) {
            padding-right: $grid-gap-xs;
            padding-left : $grid-gap-xs;
        }
    }
}

// Divider
.divider {
    position   : relative;
    overflow   : hidden;
    border     : none;
    left       : 0;
    width      : calc(100% + #{$grid-gap-xl});
    line-height: 0;
    z-index    : 10;
    @include media-breakpoint-down(lg) {
        width: calc(100% + #{$grid-gap-lg});
    }
    @include media-breakpoint-down(md) {
        width: calc(100% + #{$grid-gap-md});
    }
    @include media-breakpoint-down(xm) {
        width: calc(100% + #{$grid-gap-xm});
    }
    @include media-breakpoint-down(xs) {
        width: calc(100% + #{$grid-gap-xs});
    }

    &.top {
        top      : 1px;
        transform: rotateX(180deg);
    }

    &.bot {
        bottom   : 1px;
        transform: rotateY(180deg);
    }

    svg {
        position: relative;
        display : block;
        width   : 100%;
        height  : 80px;
        @include media-breakpoint-down(md) {
            height: 60px;
        }
        @include media-breakpoint-down(sm) {
            height: 45px;
        }
        @include media-breakpoint-down(xm) {
            height: 30px;
        }
    }

    .shape-fill {
        fill: $go4h--gray__dark;
    }
}

.divider, .mask, .splash {
    pointer-events: none;
}

.columns {
    display: flex;
    @include media-breakpoint-down(xs) {
        display: block;
    }

    ul {
        list-style-type: none;
        margin-right   : 60px;
        margin-bottom  : 0;
        @include media-breakpoint-down(md) {
            margin-right : 24px;
            margin-bottom: 16px;
        }
        @include media-breakpoint-down(sm) {
            margin-bottom: 24px;
        }

        li {
            line-height: initial;
        }
    }

    ul li::before {
        content    : "\2022";
        color      : $go4h-primary;
        font-size  : 30px;
        display    : inline-block;
        width      : 1em;
        margin-left: -1em;
    }
}

// Container
.content-padding {
    padding-top   : 80px;
    padding-bottom: 80px;
    @include media-breakpoint-down(md) {
        padding-top   : 40px;
        padding-bottom: 40px;
    }
}

// Apps Section
.apps {
    .gap-both {
        padding-right: $grid-gap-xl;
        padding-left : $grid-gap-xl;
        @include media-breakpoint-down(lg) {
            padding-right: $grid-gap-lg;
            padding-left : $grid-gap-lg;
        }
        @include media-breakpoint-down(md) {
            padding-right: $grid-gap-md;
            padding-left : $grid-gap-md;
        }
        @include media-breakpoint-down(xm) {
            padding-right: $grid-gap-xm;
            padding-left : $grid-gap-xm;
        }
        @include media-breakpoint-down(xs) {
            padding-right: $grid-gap-xs;
            padding-left : $grid-gap-xs;
        }
    }
}
