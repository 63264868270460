.spellers-apps {
    .img-box {
        position: relative;

        .img-fluid {
            position: relative;
            width   : 100%;
            height  : auto;
            z-index : 10;
        }
    }

    .splash {
        position: absolute;

        &.bot-right {
            right    : 0;
            bottom   : 50%;
            transform: translate(10%, 50%) rotate(-100deg) scale(1.2);
            z-index  : 0;
            @include media-breakpoint-down(xl) {
                transform: translate(-5%, 50%) rotate(-100deg) scale(1.2);
            }
            @include media-breakpoint-down(lg) {
                transform: translate(25%, 50%) rotate(-100deg) scale(1.2);
            }
            @include media-breakpoint-down(md) {
                transform: translate(25%, 50%) rotate(-100deg) scale(1);
            }
            @include media-breakpoint-down(md) {
                transform: translate(28%, 50%) rotate(-100deg) scale(1);
            }
        }
    }
}
