.banner {
    padding-bottom: 40px;
    @include media-breakpoint-down(md) {
        padding-bottom: 24px;
    }
    .content-button {
        @include media-breakpoint-down(sm) {
            display       : flex;
            flex-direction: column-reverse;
        }

        h2 {
            margin-bottom: 32px;
        }

        a {
            margin-bottom: 24px;
        }
    }

    .video {
        @include aspect-ratio(16,9);

        position     : relative;
        border-radius: 16px;
        z-index      : 10;
        @include media-breakpoint-down(md) {
            margin-bottom: 16px;
        }

        iframe {
            position: absolute;
            top     : 0;
            bottom  : 0;
            left    : 0;
            width   : 100%;
            height  : 100%;
            border  : 0;
        }

        .splash {
            @include aspect-ratio(4,3);

            position: absolute;
            width   : 70%;
            height  : 100%;
            @include media-breakpoint-down(md) {
                margin-bottom: 16px;
            }

            &.top-left {
                left     : 0;
                bottom   : 0;
                transform: translate(-35%, -40%);
                z-index  : -1;
                @include media-breakpoint-down(xl) {
                    transform: translate(-45%, -60%);
                }
                @include media-breakpoint-down(lg) {
                    transform: translate(-40%, -80%);
                }
                @include media-breakpoint-down(md) {
                    transform: translate(-30%, -45%);
                }
                @include media-breakpoint-down(sm) {
                    transform: translate(-24%, -54%);
                }

                img {
                    @include media-breakpoint-down(md) {
                        width: 80%;
                    }
                    @include media-breakpoint-down(sm) {
                        width: 85%;
                    }
                }
            }

            &.bot-right {
                right    : 0;
                bottom   : 0;
                transform: translate(50%, 55%);
                z-index  : -1;
                @include media-breakpoint-down(xl) {
                    transform: translate(20%, 25%);
                }
                @include media-breakpoint-down(lg) {
                    transform: translate(20%, 15%);
                }
                @include media-breakpoint-down(md) {
                    transform: translate(50%, 50%);
                }
                @include media-breakpoint-down(sm) {
                    transform: translate(40%, 36%);
                }

                img {
                    @include media-breakpoint-down(md) {
                        width: 80%;
                    }
                    @include media-breakpoint-down(sm) {
                        width: 85%;
                    }
                }
            }
        }
    }

    .hero-video {
        display: flex;
        @include media-breakpoint-down(md) {
            margin-bottom: 60px;
        }
        @include media-breakpoint-down(sm) {
            margin-bottom: 30px;
        }

    }
}
