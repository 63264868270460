.testimonial {
    background: $go4h--gray__light;

    .brand {
        text-align: center;
    }

    .img-fluid {
        img {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }

    .content {
        position      : relative;
        display       : flex;
        flex-direction: column;
        padding       : 40px 0 0 40px;
        @include media-breakpoint-down(sm) {
            padding      : 30px 0 0 30px;
            margin-bottom: 20px;
        }

        i {
            position : absolute;
            top      : 0;
            left     : 0;
            font-size: 25px;
            color    : $go4h-primary;
            @include media-breakpoint-down(sm) {
                font-size: 20px;
            }
        }

        .author {
            // font-size     : 14px;
            // letter-spacing: 2px;
            // font-weight   : 600;
        }
    }

    .img-box {
        position: relative;
    }
}
