.trainer {
    background: $white;

    .img-box {
        @include aspect-ratio(3,4);

        position: relative;
        @include media-breakpoint-down(sm) {
             @include aspect-ratio(4,3);

            margin-bottom: 24px;
        }

        img {
            overflow       : hidden;
            position       : absolute;
            width          : 100%;
            height         : 100%;
            object-fit     : cover;
            object-position: right;
            border-radius  : 16px;
            @include media-breakpoint-down(xm) {
                border-radius: 8px;
            }
        }
    }

    .content {
        .columns {
            @include media-breakpoint-down(xm) {
                margin-bottom: 16px;
            }

            ul {
                @include media-breakpoint-down(xm) {
                    margin-bottom: 0;
                }
            }
        }
    }
}
