// Sign up

.sign-up {
    position  : relative;
    min-height: calc(100vh - 160px);
    @include media-breakpoint-down(md) {
        padding-bottom: 20px;
    }
    @include media-breakpoint-down(sm) {
        min-height: calc(100vh - 140px);
    }

    .info {
        margin-bottom: 32px;
        @include media-breakpoint-down(sm) {
            margin-bottom: 16px;
        }
    }

    .group {
        display: flex;

        .form-group {
            width       : 100%;
            margin-right: 20px;
            @include media-breakpoint-down(md) {
                margin-right: 12px;
            }

            &:not(:first-child) {
                margin-right: 0;
            }
        }
    }

    .terms-policy {
        .go4h-checkbox {
            margin-bottom: 8px;
        }

        &:not(:first-child) {
            margin-bottom: 24px;
        }
    }

    .img-box {

        .img-fluid {
            width : 85%;
            height: auto;
            @include media-breakpoint-down(md) {
                width: 60%;
            }
            @include media-breakpoint-down(sm) {
                width: 40%;
            }
        }

        .splash {
            position: absolute;

            &.splash-left {
                top : 80px;
                left: 0;
                @include media-breakpoint-down(md) {
                    top: 120px;
                }
                @include media-breakpoint-down(sm) {
                    top      : initial;
                    left     : initial;
                    bottom   : 0;
                    right    : 0;
                    transform: scale(-1) translateY(-20%);
                }
            }

            &.splash-right {
                top  : 0;
                right: -65px;
                @include media-breakpoint-down(md) {
                    right: -171px;
                }
                @include media-breakpoint-down(sm) {
                    top  : -15%;
                    right: -257px;
                }
            }
        }
    }
}
