//Form

form {
    // height         : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-self     : flex-start;

    .log-in {
        color          : $go4h-primary;
        font-size      : 13px;
        margin-top     : 24px;
        font-weight    : 600;
        text-decoration: none;
        transition     : all .3s ease-in-out;
        @include hover-state() {
            text-decoration: underline;
        }
    }

    .form-group {
        margin-bottom: 20px;
        @include media-breakpoint-down(sm) {
            margin-bottom: 16px;
        }
    }

    label {
        font-size    : 14px;
        color        : $go4h--text;
        margin-bottom: 4px;
        display      : block;

        + a {
            font-size: 13px;
        }
    }

    .form-control {
        height       : 42px;
        width        : 100%;
        padding      : 0 18px;
        border       : 1px solid $go4h--gray;
        border-radius: 8px;
        @include media-breakpoint-down(sm) {
            font-size: 14px;
        }
        @include focus-state() {
            border: 1px solid $go4h-primary;
        }
        @include hover-state() {
            border: 1px solid $go4h-primary;
        }

        &.message {
            height : auto;
            resize : none;
            padding: 12px 18px;
        }
    }
}

.go4h-selector {
    display      : flex;
    align-items  : center;
    height       : 43px;
    white-space  : nowrap;
    font-weight  : 400;
    color        : $go4h--text;
    background   : $white;
    padding      : 8px 20px;
    border       : 0;
    border-radius: 40px;
    box-shadow   : $go4h-dshadow;
    transition   : all .2s ease-in-out;
    @include focus-state();
    @include hover-state() {
        color     : $go4h-primary;
        box-shadow: $go4h-dshadow-plus;
    }

    > *:not(:last-child) {
        margin-right: 8px;
    }

    i {
        position : relative;
        top      : -2px;
        font-size: 18px;

        &.icon-filter {
            font-size: 14px;
        }
    }

    span {
        font-size     : 16px;
        font-weight   : 500;
        letter-spacing: .5px;
        line-height   : 1;
        @include media-breakpoint-down(sm) {
            font-size: 14px;
        }
    }
}

.go4h-selector__options {
    overflow     : hidden;
    max-height   : 280px;
    font-size    : 14px;
    border       : 0;
    border-radius: 20px;
    box-shadow   : $go4h-dshadow-plus;

    .dropdown-item {
        padding: 8px 24px;
        @include focus-state() {
            color     : $go4h-white;
            background: $go4h-primary;
        }

        &.active {
            color     : $go4h-white;
            background: $go4h-primary;
        }
    }
}

.go4h-checkbox {
    margin-bottom: 24px;
    @include go4h-checkboxes-radios (16px, 16px, 1px, 0, 25px, 0);

    display      : flex;

    label {
        line-height: inherit;
    }
}
