// Typography

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {}

.h1, h1 {
    font-size    : 51px;
    font-weight  : 600;
    margin-top   : 0;
    margin-bottom: 16px;
    @include media-breakpoint-down(xl) {
        font-size: 44px;
    }
    @include media-breakpoint-down(lg) {
        font-size: 38px;
    }
    @include media-breakpoint-down(md) {
        font-size: 38px;
    }
    @include media-breakpoint-down(xm) {
        font-size    : 33px;
        margin-bottom: 8px;
    }
    &.onepager-h1 {
        line-height: initial;
        //color: #3C3C3C;
    }

    .faq & {
        font-size  : 67px;
        font-weight: 600;
        margin-top : 0;
        @include media-breakpoint-down(lg) {
            font-size: 58px;
        }
        @include media-breakpoint-down(md) {
            font-size: 51px;
        }
        @include media-breakpoint-down(xm) {
            font-size: 44px;
        }
    }

    .pages.sub & {
        font-size : 38px;
        margin-top: 0;
        @include media-breakpoint-down(lg) {
            font-size: 34px;
        }
        @include media-breakpoint-down(md) {
            font-size: 30px;
        }
        @include media-breakpoint-down(xm) {
            font-size: 26px;
        }
    }
}

.h2, h2 {
    font-size    : 51px;
    font-weight  : 600;
    margin-top   : 0;
    margin-bottom: 16px;
    @include media-breakpoint-down(xl) {
        font-size: 44px;
    }
    @include media-breakpoint-down(lg) {
        font-size: 38px;
    }
    @include media-breakpoint-down(md) {
        font-size: 38px;
    }
    @include media-breakpoint-down(xm) {
        font-size: 31px;
    }
}

.h3, h3 {
    font-size    : 38px;
    font-weight  : 500;
    margin-bottom: 32px;
    margin-top   : 0;
    @include media-breakpoint-down(lg) {
        font-size: 33px;
    }
    @include media-breakpoint-down(md) {
        font-size    : 28px;
        margin-bottom: 16px;
    }
    @include media-breakpoint-down(xm) {
        font-size    : 24px;
        margin-bottom: 16px;
    }
}

.h4, h4 {
    font-size  : 28px;
    font-weight: 600;
    margin-top : 0;
    @include media-breakpoint-down(md) {
        font-size: 21px;
    }
    @include media-breakpoint-down(xm) {
        font-size: 18px;
    }
}

.h5, h5 {
    font-size  : 21px;
    font-weight: 600;
    margin-top : 0;
    @include media-breakpoint-down(md) {
        font-size: 18px;
    }
    @include media-breakpoint-down(xm) {
        font-size: 16px;
    }
}

.h6, h6 {
    font-size: 18px;
    @include media-breakpoint-down(sm) {
        font-size: 16px;
    }
}

.p, p {
    font-size    : 18px;
    line-height  : 1.5;
    margin-bottom: 16px;
    margin-top   : 0;
    @include media-breakpoint-down(lg) {
        font-size: 16px;
    }
    @include media-breakpoint-down(sm) {
        margin-bottom: 16px;
    }
    @include media-breakpoint-down(xm) {
        font-size: 14px;
    }
}

ul {
    margin-bottom: 24px;
    margin-left  : 28px;
    font-size    : 18px;
    @include media-breakpoint-down(sm) {
        margin-bottom: 16px;
        font-size    : 16px;
    }
    @include media-breakpoint-down(xm) {
        font-size: 14px;
    }

    li {
        margin-bottom: 8px;
        @include media-breakpoint-down(sm) {
            margin-bottom: 4px;
        }
    }
}

a {
    text-decoration: none;
    color          : $go4h-primary;
    transition     : all .3s ease-in-out;
    @include hover-state() {
        color          : $go4h-primary;
        text-decoration: underline;
    }
}
