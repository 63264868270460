// Header

header.navbar {
    position       : relative;
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    height         : 80px;
    background     : $go4h--gray__dark;
    padding        : 0 40px;
    z-index        : 980;
    @include media-breakpoint-down(lg) {
        padding: 0 24px;
    }
    @include media-breakpoint-down(sm) {
        padding: 0 15px;
    }

    &.fixed-top {
        position: fixed;
        top     : 0;
        right   : 0;
        left    : 0;
        z-index : 1030;
    }

    .logo {
        display: block;
        width  : 208px;
        @include media-breakpoint-down(xl) {
            max-width: 280px;
        }
        @include media-breakpoint-down(lg) {
            max-width: 180px;
        }
        @include media-breakpoint-down(xs) {
            max-width: 150px;
        }
    }
}
