// Buttons

.btn {
    display        : inline-flex;
    justify-content: center;
    align-items    : center;
    align-self     : flex-start;
    min-height     : 42px;
    font-size      : 14px;
    line-height    : 1;
    letter-spacing : .2px;
    font-weight    : 600; 
    text-decoration: none;
    cursor         : pointer;
    border-radius  : 8px;
    transition     : all .2s ease-in-out;
    @include media-breakpoint-down(lg) {
        min-height   : 38px;
        border-radius: 6px;
    }
    @include hover-state() {
        text-decoration: none;
    }

    > *:not(:last-child) {
        margin-right: 8px;
    }

    i {
        font-size: 12px;
    }
}

.btn-fill {
    padding: 12px 24px;
    border : 0;
    @include media-breakpoint-down(lg) {
        padding: 10px 24px;
    }

    &.primary {
        color     : $go4h-white;
        background: $go4h-primary;
        @include hover-state() {
            color: $go4h-white;
        }
    }

    &.header {
        @include media-breakpoint-down(xm) {
            display: none;
        }
    }

    &.secondary {
        color     : $go4h-white;
        background: $go4h-secondary;
        @include hover-state() {
            color: $go4h-white;
        }
    }
}

.btn-stroke {
    background: none;
    padding   : 11px 24px;
    @include media-breakpoint-down(lg) {
        padding: 9px 24px;
    }
    @include media-breakpoint-down(xs) {
        padding: 9px 16px;
    }
    @include hover-state() {
        background: $go4h-primary;
    }

    header & {
        margin-right: 16px;
        @include media-breakpoint-down(lg) {
            margin-right: 8px;
        }
    }

    &.white {
        color : $go4h-white;
        border: 1px solid $go4h-white;
        @include hover-state() {
            color : $go4h-white;
            border: 1px solid $go4h-primary;
        }
    }

    &.dark {
        color : $go4h--text;
        border: 2px solid $go4h--text;
        @include hover-state() {
            color : $go4h-white;
            border: 2px solid $go4h-primary;
        }
    }

    &.gray {
        color : $go4h--text;
        border: 1px solid $go4h--gray;
        @include hover-state() {
            color : $go4h-white;
            border: 1px solid $go4h-primary;
        }
    }
}