// Footer

footer {
    position       : relative;
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    background     : $go4h--gray__dark;
    padding        : 14px 24px;
    margin         : auto 0 0;
    @include media-breakpoint-down(md) {
        flex-direction: column;
        align-items   : flex-start;
        padding       : 24px;
    }
    @include media-breakpoint-down(sm) {
        padding: 15px;
    }

    > *:not(:last-child) {
        @include media-breakpoint-down(md) {
            margin: 0 0 32px;
        }
    }

    a {
        color     : $go4h-white;
        transition: all .2s ease-in-out;
        @include media-breakpoint-down(lg) {
            font-size: 14px;
        }
        @include hover-state() {
            text-decoration: none;
            color          : $go4h-primary;
        }
    }

    .copy-r {
        display    : flex;
        align-items: center;
        @include media-breakpoint-down(md) {
            flex-direction: column;
            align-items   : flex-start;
            margin        : 0 0 24px;
        }

        .logo {
            img {
                width       : 200px;
                margin-right: 24px;
                @include media-breakpoint-down(md) {
                    margin-bottom: 8px;
                }
            }
        }

        span {
            font-size  : 14px;
            font-weight: 500;
            color      : $go4h--gray;
            @include media-breakpoint-down(lg) {
                font-size: 13px;
            }
        }
    }

    .footer-nav {
        display: flex;
        @include media-breakpoint-down(xm) {
            flex-direction: column;
        }

        a {
            text-transform : uppercase;
            text-decoration: none;
            letter-spacing : 1px;
            font-weight    : 600;

            &:not(:last-of-type) {
                margin: 0 40px 0 0;
                @include media-breakpoint-down(lg) {
                    margin: 0 24px 0 0;
                }
                @include media-breakpoint-down(xm) {
                    margin: 0 0 16px;
                }
            }
        }
    }
}
