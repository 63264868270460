//  ========================================================================
//  Variables
//  ======================================================================== 
$enable-grid-classes  : true;

$grid-breakpoints     : ( xs: 0, xm: 420px, sm: 576px, md: 768px, lg: 991px, xl: 1200px ,xxl: 1500px );
$container-max-widths : ( sm: 540px, md: 720px, lg: 960px, xl: 1140px );

$grid-columns         : 12;
$grid-gutter-width    : 30px;
$grid-row-columns     : 6;

$bst-spacing          : $grid-gutter-width / 2;
$scroll-bar-width     : 17px;

//  ========================================================================
//  Colors
//  ========================================================================

$white                : #fff !default;
$white-10             : rgba($white, 0.1) !default;
$white-20             : rgba($white, 0.2) !default;
$white-30             : rgba($white, 0.3) !default;
$white-50             : rgba($white, 0.5) !default;

$black                : #000 !default;
$black-10             : rgba($black, 0.1) !default;
$black-20             : rgba($black, 0.2) !default;
$black-25             : rgba($black, 0.25) !default;
$black-30             : rgba($black, 0.3) !default;
$black-50             : rgba($black, 0.5) !default;

$gray                 : #8f8f8f !default;
$gray-10              : rgba($gray, 0.1) !default;
$gray-20              : rgba($gray, 0.2) !default;
$gray-30              : rgba($gray, 0.3) !default;
$gray-50              : rgba($gray, 0.5) !default;

$go4h-black           : #1C1C1C !default;
$go4h-white           : #ffffff !default;

$go4h--text           : #3C3C3C !default;

$go4h--gray__dark     : #252525 !default;
$go4h--gray           : #BCBCBC !default;
$go4h--gray__light    : #F5F5F5 !default;

$go4h-primary--dark   : #BC5400 !default;
$go4h-primary         : #F58220 !default;
$go4h-primary--light  : #FFB352 !default;

$go4h-secondary--dark : #00617E !default;
$go4h-secondary       : #008FAD !default;
$go4h-secondary--light: #56BFDF !default;

// $gradient     : linear-gradient(90deg, green 0%, orange 100%);
$go4h-dshadow         : 0 1px 1px rgba(35, 39, 53, 0.1), 0 2px 12px rgba(95, 99, 104, 0.06);
$go4h-dshadow-plus    : 0 1px 2px rgba(35, 39, 53, 0.1), 0 6px 12px rgba(95, 99, 104, 0.06);

$body-bg              : $go4h--gray__light !default;
$body-color           : $go4h--text !default;

//  ========================================================================
//  Fonts
//  ========================================================================
$font-main            : 'Inter', Arial, sans-serif;
//  font-weights:                300     400     500     700     900      //

//  ========================================================================
//  custom grid
//  ========================================================================
$grid-gap-xl          : 105px;
$grid-gap-lg          : 65px;
$grid-gap-md          : 25px;
$grid-gap-xm          : 15px;
$grid-gap-xs          : 0;