.speler {
    position: relative;
    z-index : 100;

    .container-fluid {
        .img-box {
            @include aspect-ratio(4,3);

            position: relative;
            @include media-breakpoint-down(md) {
                margin-bottom: 24px;
            }

            img {
                position     : absolute;
                overflow     : hidden;
                width        : 100%;
                height       : 100%;
                object-fit   : cover;
                border-radius: 16px;
                @include media-breakpoint-down(xm) {
                    border-radius: 8px;
                }
            }

            .splash {
                @include aspect-ratio(4,3);

                position: absolute;
                width   : 70%;
                height  : 100%;

                &.bot-left {
                    right    : 0;
                    bottom   : 0;
                    transform: translate(-90%, -40%);
                    z-index  : -1;
                    @include media-breakpoint-down(lg) {
                        transform: translate(-40%, -55%)rotate(50deg);
                    }
                    @include media-breakpoint-down(md) {
                        transform: translate(50%, -0%) rotate(137deg);
                    }

                }
            }
        }

        .content {
            .columns {
                @include media-breakpoint-down(xm) {
                    margin-bottom: 16px;
                }

                ul {
                    @include media-breakpoint-down(xm) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
