//  ========================================================================
//  Modules & Variables
//  ========================================================================
@import "functions";
@import "variables";
@import "variables-custom";
@import "mixins";
@import "grid";
@import "transitions";
@import "utilities";

//  ========================================================================
//  Import Base SCSS/CSS
//  ========================================================================
@import "base";
@import "type";
@import "form";
@import "buttons";

//  ========================================================================
//  Import Custom SCSS/CSS
//  ========================================================================
@import "blocks/header";
@import "blocks/faq";
@import "blocks/log-in";
@import "blocks/sign-up";
@import "blocks/forgot-pass";
@import "blocks/home__banner";
@import "blocks/home__partners";
@import "blocks/home__trainer";
@import "blocks/home__coach";
@import "blocks/home__speler";
@import "blocks/home__club";
@import "blocks/home__testimonial";
@import "blocks/home__apps";
@import "blocks/home__spellers-apps";
@import "blocks/footer";

//  ========================================================================
//  Vendors
//  ========================================================================

@import "~video-react/styles/scss/video-react";
@import '~react-toastify/dist/ReactToastify.css';
