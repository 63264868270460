.apps {
    position: relative;
    z-index : 100;

    &::before {
        position  : absolute;
        top       : 0;
        left      : 0;
        content   : "";
        width     : 100%;
        height    : 50%;
        background: $go4h--gray__light;
        z-index   : -1;
    }

    .container-fluid {
        @include media-breakpoint-down(xm) {
            padding-left : 15px;
            padding-right: 15px;
        }
        background: $go4h--gray__dark;
        @include media-breakpoint-down(xl) {
            padding-top: 80px;
        }
        @include media-breakpoint-down(md) {
            padding-top: 60px;
        }
    }

    .card {
        @include media-breakpoint-down(md) {
            margin-bottom: 24px;
        }

        .card-img-top {
            width        : 100%;
            height       : auto;
            margin-bottom: 24px;
            border-radius: 16px;
            @include media-breakpoint-down(md) {
                border-radius: 8px;
                margin-bottom: 16px;
            }
        }

        .card-body {
            color: $white;

            h4 {
                margin-bottom: 16px;
            }

            p {
                margin-bottom: 24px;
            }

            .link {
                font-size      : 21px;
                font-weight    : 600px;
                text-decoration: none;
                transition     : all .3s ease-in-out;
                @include media-breakpoint-down(md) {
                    font-size: 18px;
                }
                @include media-breakpoint-down(sm) {
                    font-size: 16px;
                }
                @include hover-state() {
                    text-decoration: underline;
                }

            }

            .app-list {
                display: flex;

                > *:not(:last-child) {
                    margin: 0 16px 0 0;
                }

                > * {
                    @include media-breakpoint-down(sm) {
                        max-width: 180px;
                    }
                }
            }

            a {
                max-width: 140px;
                height   : auto;

                img {
                    width : 100%;
                    height: auto;
                }
            }
        }
    }

    .content {
        display       : flex;
        flex-direction: column;
        color         : $go4h-white;
        @include media-breakpoint-down(sm) {
            padding: 0 25px;
        }
        @include media-breakpoint-down(xm) {
            padding: 0 15px;
        }
        @include media-breakpoint-down(xs) {
            padding: 0;
        }

        form {
            overflow       : hidden;
            display        : flex;
            flex-direction : row;
            justify-content: space-between;
            align-self     : flex-start;
            background     : $go4h-white;
            border-radius  : 30px;

            input {
                width  : calc(100% - 120px);
                border : 0;
                padding: 0 16px 0 24px;
                @include media-breakpoint-down(sm) {
                    font-size: 14px;
                    padding  : 0 8px 0 16px;
                }
            }
        }
    }

    .splash {
        position: absolute;

        img {
            @include media-breakpoint-down(sm) {
                width: 70%;
            }
        }

        &.top-left {
            left   : 0;
            top    : -24%;
            z-index: -1;
            @include media-breakpoint-down(lg) {
                top: -25%;
            }
            @include media-breakpoint-down(md) {
                top: -30%;
            }
            @include media-breakpoint-down(sm) {
                top: -11%;
            }
            @include media-breakpoint-down(xm) {
                top: -8%;
            }
        }
    }
}
