.club {
    background: $go4h--gray__dark;
    position  : relative;

    .splash {
        position: absolute;

        &.bot-right {
            right  : 0;
            top    : -20%;
            z-index: -1;
            @include media-breakpoint-down(xl) {
                top: -22%;
            }
            @include media-breakpoint-down(md) {
                top: -26%;
            }
            @include media-breakpoint-down(sm) {
                top: -22%;
            }
            @include media-breakpoint-down(xm) {
                top: -20%;
            }
            @include media-breakpoint-down(xs) {
                top: -18%;
            }
        }
    }

    .container-fluid {
        background: $go4h--gray__dark;
    }

    .content {
        color: $white;

        a {
            text-decoration: none;
        }

        .columns {
            @include media-breakpoint-down(xm) {
                margin-bottom: 16px;
            }

            ul {
                @include media-breakpoint-down(xm) {
                    margin-bottom: 0;
                }
            }
        }
    }

    .img-box {
        @include aspect-ratio(3,4);

        position: relative;
        @include media-breakpoint-down(sm) {
            @include aspect-ratio(4,3);

            margin-bottom: 24px;
        }

        img {
            overflow     : hidden;
            position     : absolute;
            width        : 100%;
            height       : 100%;
            object-fit   : cover;
            border-radius: 16px;
            @include media-breakpoint-down(xm) {
                border-radius: 8px;
            }
        }
    }
}
