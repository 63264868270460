.partners {
    position: relative;
    padding : 52px 0;
    z-index : 100;
    @include media-breakpoint-down(md) {
        padding: 32px 0;
    }

    &::before {
        position  : absolute;
        content   : "";
        top       : 0;
        left      : 0;
        width     : 100%;
        height    : 100%;
        background: $go4h--gray__light;
    }

    .column {
        position       : relative;
        display        : flex;
        justify-content: space-around;
        height         : 80%;
        width          : auto;

        figure {
            @include aspect-ratio(1,1);

            position: relative;
            width   : 12%;
            @include media-breakpoint-down(md) {
                width: 16%;
            }
            @include media-breakpoint-down(sm) {
                width: 12%;
            }
            @include media-breakpoint-down(xs) {
                width: 16%;
            }

            img {
                overflow  : hidden;
                position  : absolute;
                width     : 100%;
                height    : 100%;
                object-fit: contain;
            }
        }
    }

    .splash {
        position: absolute;

        &.bot-left {
            position: absolute;
            left    : 0;
            bottom  : -59%;
            z-index : -1;
            @include media-breakpoint-down(lg) {
                right    : 0;
                left     : inherit;
                bottom   : -62%;
                transform: rotate(90deg);
            }
            @include media-breakpoint-down(md) {
                bottom: -80%;
            }
            @include media-breakpoint-down(sm) {
                bottom: -58%;
            }
            @include media-breakpoint-down(xm) {
                bottom: -53%;
                right : -7%;
            }
        }

        img {
            @include media-breakpoint-down(xm) {
                width: 80%;
            }
        }
    }
}
